import React from "react"; // eslint-disable-line
import ReactDOM from 'react-dom/client';
import {Auth0Provider} from '@auth0/auth0-react';
import * as serviceWorker from './serviceWorker';

import Auth0 from "./Components/Auth0"; // eslint-disable-line

import './index.css';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_PLAYGROUND_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
    <Auth0 />
  </Auth0Provider>,
);

serviceWorker.unregister();
